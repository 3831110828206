/* all colors mixin stuff */

$primary-color: #fb642d;
$primary-dark: #353535;
$primary-light: #f5f5f5;

$secondary-color: #f14c10;
$secondary-light: #fbfbfb;
$secondary-dark: #252326;

$superdark: #001329;

$gray-dark: #767676;
$gray-dark2: #9a9a9a;
$gray-light: #a4a4a4;
$gray-lighter: #ccd0d4;
$gray-superlight: #e0e0e0;

$color-superlight: #fef8f5;

$notify-blue: #3a7fe6;
$notify-blue-light: #00b0ff;
$notify-green: #00a69a;
$notify-green-light: #4cd1c7;
$notify-red: #f3033c;
$notify-violet: #6a2dfb;
$notify-yellow: #ffc164;

$white: #ffffff;
$black: #000000;

$mdc-theme-primary: $secondary-color;
$mdc-theme-secondary: $primary-light;
$mdc-theme-on-primary: $primary-color;
$mdc-theme-on-secondary: $notify-red;

