/* left panel menu */

.leftmainmenu {
	
	.items {
		top: 110px;
	}
	
}

.onebid-left {

	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
	margin-bottom:20px;
	
	.maintitle {
		@include font-main;
		@include fw-500;
		@include fs-16;
		margin-bottom: 15px;
		color: $secondary-dark;
	}
	.subtitle {
		@include font-main;
		@include fw-400;
		@include fs-12;
		color: $gray-dark;
	}

	&.box-bg-dark {
		
		color: $white;
		h2 {
			@include font-main;
			@include fw-500;
			@include fs-16;
			padding-bottom: 20px;
			color: $white;
		}

		h3 {
			@include font-main;
			@include fw-400;
			@include fs-12;
			color: $gray-light;
		}
	}

	&.box-bg-white {
		
		color: $secondary-dark;

		/* h2 i h3 z czasem do usunięcia */
		h2 {
			@include font-main;
			@include fw-500;
			@include fs-16;
			padding-bottom: 20px;
			color: $secondary-dark;
		}
		h3 {
			@include font-main;
			@include fw-400;
			@include fs-12;
			color: $gray-dark;
		}
		
		.onebid-left-cat {
			
			.list-group-item {
				
				border: 0px;
				border-radius: 0px;
				padding: 5px 15px 5px 13px;
				margin: 0px -20px;

				a {

					.name {
						color: $secondary-dark;
					}
					
					&.active .name {
						color: $secondary-dark;
						font-weight: bold;
					}
					
					&:hover .name {
						color: $primary-color;
					}
					.count {
						color: $secondary-dark;
					}
				
				}

				label {
					
					.name {
						color: $secondary-dark;
						line-height: 24px;
						position: relative;
					}
					
				}
				
				&.part {
					display: flex;
					justify-content: space-between;
					border-top: 1px solid #eee;
					border-bottom: 1px solid #eee;
					//	border-left: 5px solid #fff;
					color: $secondary-dark;
					background: #f5f5f5;
					
					&:hover {
						cursor: pointer;
						background: #fafafa;
					}
					
					&:not(.open) {

						.arrow_up {
							display: none;
						}

					}
					
					&.open {

						.arrow_down {
							display: none;
						}

					}
					
					.icon {
						font-size: 15px;
						line-height: 15px;
						color: #fb642d;
					}
				
				}
				
				&.session-list {
					display: none;
					border-left: 2px solid $gray-superlight;
					&.open {
						display: block;
						background-color: #fff;
					}
					
				}
			
			}
			
			.icons {
				width: 20px;
				margin-right: 5px;
			}
			
			a {
				color: #252326;
				i {
					color: $primary-color;
					font-size: 20px;
				}
				.name {
					display: flex;
					justify-content: flex-start;
					
					&.child1 {
						padding-left: 15px;
					}
					&.child2 {
						padding-left: 30px;
					}
					&.child3 {
						padding-left: 45px;
					}
				}
				&:not(.open) {
					.arrow_up {
						display: none;
					}
				}
				&.open {
					.arrow_down {
						display: none;
					}
				}
			}
			
		}
		
		.onebid-left-painting {
			
			.squares {
				
				a.color {
					display: inline-block;
					width: 20px;
					height: 20px;
					margin-right: 4px;
					margin-bottom: 6px;
					border: 1px solid #888;
					text-align: center;
					font-weight: 700;
					color: #f3033c;
					
					&:hover {
						border:1px solid #aaa;
					}
					
					.material-icons {
						font-size: 14px;
						color: #fff;
					}

				}
				
			}
			
		}
		
		&.extra {
			.img {
				position: relative;
				text-align: center;
				img {
					border-top-left-radius:14px;
					border-top-right-radius:14px;
				}
			}
			.dot {
				@include fs-11;
				@include font-head;
				position: absolute;
				right:20px;
				bottom: -38px;
				border-radius:50%;
				width: 80px;
				height: 80px;
				color: #fff;
				text-align: center;
				line-height:80px;
				background: $notify-green;
			}
			.info {
				padding:20px;

				.price {
					@include font-head;
					font-size: 24px;
					color: #484848;
					line-height: 1.25;
					margin-bottom: 10px;
				}
			}
		}
		
		&.archive {
			
			.list-group-item {
				border-top: 0px;
				border-left: 0px;
				border-right: 0px;
			}
			.list-group-item:last-of-type {
				border-bottom: 0px;
			}
		}
		
	}

	&-cat {
		
		a {
			.name {
				@include fs-12;
				line-height: 24px;
				color: $secondary-dark;
				position: relative;
				padding-right:30px;
			}
			
			&.active .name {
				color: $secondary-dark;
				font-weight: bold;
			}
			
			&:hover {
				
				.name {
					color: $primary-color;
				}

			}
			
			.count {
				@include fs-12;
				height: 24px;
				line-height: 24px;
				color: $secondary-dark;
				position: absolute;
				right:0;
			}
		}
		
		h4 {
			font-size: 14px;
		}
	
		label {
			display: block;
			cursor: pointer;
			
			.name {
				@include fs-12;
				line-height: 24px;
				color: $secondary-dark;
				position: relative;
				
				.checkicon {
					margin-right: 6px;
					color: #969696;
				}
			}
			
			&:hover {
				
				.name {
					color: $primary-color;
				}
				
			}
			
			.count {
				@include fs-12;
				height: 24px;
				line-height: 24px;
				color: $secondary-dark;
				position: absolute;
				right:0;
			}
			
		}
	}

	&-company {

		margin-bottom:20px;

		.logo {
			display: block;
			text-align: center;
			line-height: 1.58;
			margin: 5px 0 20px 0;

			img {
				max-height: 44px;
				max-width: 100%;
				width: auto;
				height: auto;
			}

		}

		.name {
			@include fs-16;
			@include fw-500;
			color: $primary-dark;
			line-height: 1;
			text-align: center;
		}

	}
  
	.offerlist {
		
		.selected {
			background: #f5f5f5;
		}
		
		img.logo {
			max-height: 30px;
		}
		
		.count {
			color: $primary-color;
		}
		
	}
	
	.right-side-session {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		white-space: nowrap;
		
		i {
			margin-top: 0px;
			margin-bottom: 3px;
			text-align: right;
			display: inline-block;
		}
	}
	
}
