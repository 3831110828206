#livepage {

	margin-top:10px;
	
	.header {
		background: #fafafa;
		margin-top: -10px;
		
		@media screen and (max-width: 575.98px) {
			margin-top: -20px;
		}
		
		h4 {
			line-height: 60px;
			margin-bottom: 0px;
			font-size: 17px;
			
			@media screen and (max-width: 575.98px) {
				font-size: 14px;
				line-height: 45px;
			}
		}
		
		.logo {
			max-height:40px;
			margin:10px 0px;
		}
		
		.progress {
			height:5px;
			margin-bottom:0px;
			width:100%;
			
			.progress-bar {
				height:5px;
			}
		}
	}
	
	#livepanel {
	
		.extraactionbutton {
			width: 31px;
			height: 31px;
			text-align: center;
			margin-top: 7px;
			padding: 0px;
			margin-right: 5px;
			
			.ico {
				display: inline-block;
				font-size: 16px;
				line-height: 20px;
			}
			
		}
		
		.longtextcontainer {
		
			max-height: 150px;
			position: relative;
			overflow: hidden;

			.read-more {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				text-align: center;
				margin: 0;
				padding-top:80px;
				padding-bottom:5px;
				background-image: linear-gradient(to bottom, transparent, #fefefe);
				
				.btn {
					background: #fff;
					
					&:hover {
						background: $gray-dark;
					}
				}
			}

		}

	}

	@media(max-width: 201px) {
		#pipapp {
			display: block;
			width: 100%;
			height: 100%;
			left: 0px;
			top: 0px;
			position: fixed;
			z-index: 10000;
			background: #fff;
			text-align: center;
			
			.nr {
				font-size: 26px;
				font-weight: bold;
				margin-top: 10px;
				margin-bottom: 10px;
			}
			.img img {
				max-width: 100%;
				max-height: 75px;
				height: auto;
				padding-left: 10px;
				padding-right: 10px;
			}
			.price {
				font-size: 20px;
				margin-top: 10px;
			}
		}
	}
	@media(min-width: 201px) {
		#pipapp {
			display: none;
		}
	}
	
	#bidLockSwitchInput {
	
		&.slideToUnlock {
			
			position: relative;
			text-align: left;
			height: 32px;
			line-height: 32px;
			border-radius: 25px;
			background: $color-superlight;
			max-width: 250px;
			margin: 0px auto;
		
			.progressBar {
				position: absolute;
				left:0;
				top:0;
				width: 0;
				height: 100%; 
				border-radius: 25px;
			} 
			.text {
				color: $primary-dark;
				font-size:13px;
				position: absolute;
				left: 0px;
				top: 0;
				width: 100%;
				height: 100%;
				padding-left: 40px;
				border-radius: 25px;
				background-color: transparent;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				-webkit-touch-callout: none; /* iOS Safari */
				-webkit-user-select: none; /* Safari */
				-khtml-user-select: none; /* Konqueror HTML */
				-moz-user-select: none; /* Firefox */
				-ms-user-select: none; /* Internet Explorer/Edge */
				user-select: none;
			} 
			
			.drag {
				position: absolute;
				width: 32px;
				height: 32px;
				line-height: 32px;
				left: 0%;
				display: inline-block;
				border-radius: 25px;
				cursor: pointer;
				border: 2px solid #fff;
				background: $primary-color;
				color: #fff;
				text-align: center;
				font-size: 22px;
				
				span {
					margin-top: -2px;
					animation-name: bump;
					animation-duration: 2s;
					animation-iteration-count: infinite;
					
					@keyframes bump {
						0% {margin-left: 0px;}
						25% {margin-left: 0px;}
						50% {margin-left: 4px;}
						25% {margin-left: 0px;}
						100% {margin-left: 0px;}
					}
				}
			}
			
			.unlocked {
				background-color: $gray-light;
			}
			
		}
		
	}
}

#widgetnewwindow {
	background: $primary-color;
	border: 1px solid #fff;
	border-radius: 50%;
	bottom: 15px;
	font-size: 22px;
	height: 34px;
	line-height: 34px;
	opacity: .8;
	position: fixed;
	left: 10px;
	text-align: center;
	width: 34px;
	z-index: 10;
	
	a {
		color: #fff;
	}
}

