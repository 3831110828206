.appdonwloadinfo {
	
	position: relative;
	
	.contentbox {
		display: flex;
		background: #fff;
		border: 1px solid #ddd;
		position: absolute;
		height: 75%;
		width: 90%;
		top: 13%;
		left: 5%;
		-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
		-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
		z-index: -1;
	}

	.store {
	
		img {
			border-radius: 10px;
		}
		
		&:hover {

			img {
				-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);
				-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);
				box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);
			}
		
		}
		
	}
}

.lots-scroll-list {
	
	.box-eq {
		width:220px;
		min-width: 220px;
		max-width: 220px;
	}
	
	.lots-list {
		
		.lots-list-box {
			
			border: 1px $white solid;
			border-bottom: 0;
			
			&:hover {
				border: 1px $primary-light solid;
			}
			
			a {
				color: auto;
				display: block;
			}
			
			.top-date {
				@include font-main;
				@include fs-12;
				@include fw-400;
				color: $gray-dark;
				margin-bottom: 5px;
			}
			
			.imgbox {
				margin: 10px 0;
				
				img {
					max-height: 135px;
					margin: 0px auto;
				}
			}
			
			.price {
				@include font-head;
				@include fs-20;
				@include fw-400;
				line-height: 0.54;
				color: $primary-dark;
				padding: 10px 0;
				text-align: center;
				
				sub {
					bottom: 0px;
					color: $gray-dark;
					@include fs-12;
				}
			}
			
			.name {
				@include font-main;
				@include fs-13;
				@include fw-400;
				line-height: 1.5;
				color: $primary-dark;
				padding-top: 8px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				text-align: center;
			}
			
		}
		
	}
	
}


