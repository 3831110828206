
#offeritempage {

	img.logo {
		max-height: 40px;
	}

	.img {
		
		img {
			max-height: 500px;
		}
		
	}
	
	.cart-group {
		border-radius:4px;
		display: flex;
		justify-content: center;
		width: 120px;
		border: 1px solid #a4a4a4;
		
		.btn {
			border-radius:0;
			border: 0;
			outline: none;
			font-weight: 700;
			text-align: center;
			padding: 5px;
					
			&:active, &.active{
				border: none;
				outline: none;
			}
		}
		
		input, button{
			flex: 1 1 33.33%;
			width: 33.33%;
			max-width: 33.33%;
			border: 0px;
			text-align: center;
			outline: none;
		}
	}
	
}

/* Także w lot.scss */
#offerCartModal {

	.modal-header{
		border-bottom: 0px;
		padding: 24px 24px 12px;
	}
	
	.modal-body{
		padding: 12px 24px;
	}
	
	.modal-footer{
		border-top: 0;
		
		@media(max-width: 640px) {
			.btn {
				display: block;
				width: 100%;
			}
		}
		
	}
	.name {
		@include font-main;
		font-size: 12px;
		color: #767676;
		margin-bottom: 4px;
	}

	h6 {
		font-size: 16px;
		font-weight: 600;
		color: #252326;
		line-height: 20px;
		@include font-main;
		margin: 0;
		padding: 0;
	}

	#offerCartModalSummary {
		font-size: 22px;
		font-weight: 600;
		@include font-main;
		color: #252326;
	}
	
}



#cartPage {

	margin-top: 32px;
	
	.rounded-circle {
		background: #ccd0d4;
		width: 35px;
		height: 35px;
		color:#fff;
		text-align: center;
		vertical-align: middle;
		@include font-main;
		font-weight: 600;
		line-height: 35px;
		font-size:16px;
		margin-right: 15px;
		display: inline-block;
		&.active{
			background: #fb642d;
		}
	}

	.top-bar {
		margin-top:16px;
		margin-bottom:16px;
		
		>div {
			display: flex;
			align-items: center;
		}

		h3 {
			color: #484848;
			font-size: 16px;
			@include font-main;
			font-weight: 600;
			position: relative;
    		text-indent: 0;
    		margin:0;
    		width: calc(100% - 50px);
    		&:after{
    			content: attr(title);
			    position: absolute;
			    top: 0;
			    left: 0;
				background: #edeff1;
			    padding-right: 20px;
    		}

			.line{
				display: block;
			    position: absolute;
			    right: 0;
			    top: 49%;
			    bottom: 49%;
			    min-width: 90%;
			    border-bottom: 1px solid #ccd0d4;
			}
		}

	}

	.cart-info {
		
		margin-bottom: 15px;

		.box-bg-white {
			border-radius: 8px;	
			color: #484848;
			font-family: "Poppins";
			padding: 20px;

			&.info-box{
				border:2px solid #056ad7;
				margin-bottom: 16px;
				color: #484848;
				padding: 16px;

				h3{
					font-size: 20px;
					margin-bottom:3px;
				}

				.material-icons{
					color: #056ad7;
					font-size: 38px;
					margin-top: -12px;
				}
			}
			
			&.delivery {
				h5 {
					margin-bottom:4px;
					color:#767676;
				}
				p {
					font-size: 14px;
					font-weight: 600;
					font-family: "Poppins";
					margin-bottom: 16px;
				}
			}
			h3 {
				font-size:16px;
				line-height: 25px;
				font-weight: 600;
				margin-bottom: 24px;
				
				&.section {
					border-bottom: 1px solid #ddd;
					padding-bottom: 5px;
				}
			}
			p.text-right{
				color: #767676;
				font-size: 14px;
				line-height: 21px;
				margin-bottom: 4px;
			}
			h5 {
				font-size:12px;
				margin-bottom: 16px;
			}
			
			.logo {
				max-height: 50px;
			}

			.cart-item-remove {
				border: none;
				color: #fb642d;
				background: transparent;
				font-size: 16px;
				line-height: 16px;
				&:hover,&:active,&.active,&:focus,&.focus{
					border: none;
					outline: none;
				}
				fa {
					font-weight:300;
				}
			}
			
			td {
				padding:16px 8px;
				vertical-align: top;
				border-top: 0px;
				border-bottom: 1px solid #dee2e6;
			
				&.img {
					width: 15%;
					
					img {
						max-height: 40px;
					}
					
				}
				&.name {
					width: 75%;
				}
				&.price {
					width: 10%;
					text-align: right;
					white-space: nowrap;
				}
				&.icon {
					width: 10%;
					text-align: right;
				}

				h3 {
					margin-bottom: 3px;
					color: #252326;
					line-height: 18px;
				}
				p {
					font-size:12px;
					color:#252326;
				}
				.cart-group {
					width: 100px;
				}
			}
			
			.payments {
				padding: 0 50px;
			}
			.payment-box {
				text-align: center;
				align-items: center;
				display:flex;
				flex-direction: column;
				justify-content: space-between;
				border-radius: 8px;
				border:2px solid transparent;
				background: #fff;
				margin:0 8px;
				padding: 19px 0;
				color:#484848;
				font-weight: 600;
				flex:1 1 auto;
				width: 150px;
				&.active{
					border-color:#fb642d;
				}
				&.disabled{
					background: #f5f5f5;
				}
				img{
					height: 48px;
				}
			}
			
			.cart-group {
				border-radius: 4px;
				display: flex;
				justify-content: center;
				width: 120px;
				border: 1px solid #a4a4a4;
				
				.btn {
					border-radius:0;
					border: 0;
					outline: none;
					font-weight: 700;
					text-align: center;
					padding: 5px;
					
					&:active, &.active {
						border: none;
						outline: none;
					}
				}
				
				input, button {
					flex: 1 1 33.33%;
					width: 33.33%;
					max-width: 33.33%;
					border: 0px;
					text-align: center;
					outline: none;
				}
			}
		}
	
	}
	
	.summary {

		.box-bg-white {
			border-radius: 8px;	
			color: #484848;
			font-family: "Poppins";
			padding: 24px;
			
			h2 {
				font-size: 22px;
				line-height: 33px;
				text-align: right;
				margin-bottom: 24px;
			}
			
			h3 {
				font-size:16px;
				line-height: 25px;
				font-weight: 600;
				margin-bottom: 24px;
			}
			
			.order-btn {
				text-transform: none;
				font-size: 16px;
				line-height: 25px;
				padding: 10px 0 11px;
			}
		
		}
		
	}
	
	.seller-info {
		padding: 24px 0;
		
		&:not(:last-child){
			border-bottom: 1px solid #bdbdbd;
		}
		
		p {
			font-size: 12px;
			color: #767676;
			line-height: 18px;
			margin-bottom: 0px;
		}
		
		h4 {
			font-weight: 600;
			font-size: 14px;
			color: #252326;
			margin-bottom: 12px;
		}
		
		h5 {
			font-size: 20px;
		}
		
		.items {
			
			.item {
				
				&:not(:last-child){
					border-bottom: 1px solid #ddd;
				}
				
				img {
					max-height: 40px;
				}
				
			}
			
		}
	}
	
}


#offerPaymentModal {
	.modal-header{
		border-bottom: none;
	}
	.modal-footer{
		border-top:none;
		a{
			margin-bottom: 20px;
			text-transform: none;
		}
	}
	.rounded-circle{
		background: #00a69a;
		width: 57px;
		height: 57px;
		display: inline-flex;
		margin-bottom: 16px;
		justify-content: center;
		align-items: center;
	}
	.material-icons{
		color:#fff;
		font-weight: bold;
		font-size: 44px;
	}
	h1{
		font-size: 22px;
		font-weight: 600;
		line-height: 33px;
		color: #484848;
		@include font-main;
		margin-bottom: 16px;
	}
	p{
		color:#252326;
		font-size: 14px;
		line-height: 20px;
		margin-bottom: 16px;
	}

}

@media(max-width:767px) {

	#cartPage {
		padding-left:0;
		padding-right: 0;


		h3 {
			font-size: 16px;
			line-height: 25px;
			color: #484848;
			@include font-main;
			font-weight: 600;
			padding-left: 16px;
		}
		.box-bg-white {
			padding: 16px;
			&:not(.info-box){
				border-radius: 0;	
			}
			&.info-box {
				margin-left: 16px;
				margin-right: 16px;
				h3 {
					padding-left: 0;
				}
				p {
					font-size: 16px;
				}
			}
			.payments-wrapper {
				width: 200%;
			}
			.payments {
				width: 100%;
				padding: 0;
				margin-top: 15px;
				margin-bottom: 15px;
			}
		}
		table {
			h3 {
				padding-left: 0;
			}
		}
		.summary {
			padding-left: 0;
			padding-right: 0;	
			
			.box-bg-white{
				padding: 16px;

				h2{
					margin-bottom: 0;
				}
			}
			h3 {
				font-size: 16px;
				line-height: 25px;
				color: #484848;
				@include font-main;
				font-weight: 600;
				padding-left: 16px;
			}

			p.topay {
				color: #767676;
				font-size: 14px;
				line-height: 30px;
				@include font-main;
				margin-bottom: 0px;
			}
		}
		
		.listofitems {
			margin-bottom: 24px;
			padding-left: 0;
			padding-right: 0;
			
			.btn-outline-primary{
				display: block;
			}

		}
		
		.top-bar {
			margin-top:0;
			margin-bottom:0;
			h3 {
				font-size: 22px;
				color:#484848;
				padding-left: 0;
				width: 100%;
				margin-bottom: 5px;
			}
			h5 {
				color:#767676;
				font-size:14px;
			}
		}
	}
}

#cartPage {

	.progress-circle {
	    width: 62px;
	    height: 62px;
	    line-height: 62px;
	    background: none;
	    margin: 0 auto;
	    box-shadow: none;
	    position: relative;
	}
	.progress-circle:after {
	    content: "";
	    width: 100%;
	    height: 100%;
	    border-radius: 50%;
	    border: 8px solid #e4e4e4;
	    position: absolute;
	    top: 0;
	    left: 0;
	}
	.progress-circle > span {
	    width: 50%;
	    height: 100%;
	    overflow: hidden;
	    position: absolute;
	    top: 0;
	    z-index: 1;
	}
	.progress-circle .progress-circle-left {
	    left: 0;
	}
	.progress-circle .progress-circle-bar {
	    width: 100%;
	    height: 100%;
	    background: none;
	    border-width: 8px;
	    border-style: solid;
	    position: absolute;
	    top: 0;
	}
	.progress-circle .progress-circle-left .progress-circle-bar {
	    left: 100%;
	    border-top-right-radius: 80px;
	    border-bottom-right-radius: 80px;
	    border-left: 0;
	    -webkit-transform-origin: center left;
	    transform-origin: center left;
	}
	.progress-circle .progress-circle-right {
	    right: 0;
	}
	.progress-circle .progress-circle-right .progress-circle-bar {
	    left: -100%;
	    border-top-left-radius: 80px;
	    border-bottom-left-radius: 80px;
	    border-right: 0;
	    -webkit-transform-origin: center right;
	    transform-origin: center right;
	    animation: loading-1 0.5s linear forwards;
	}
	.progress-circle .progress-circle-value {
	    width: 90%;
	    height: 90%;
	    border-radius: 50%;
	    background: #edeff1;
	    font-size: 14px;
	    font-weight: 600;
		@include font-main;
	    color: #484848;
	    line-height: 60px;
	    text-align: center;
	    position: absolute;
	    top: 5%;
	    left: 5%;
	}

	.progress-circle.orange .progress-circle-bar {
	    border-color: #fb642d;
	}
	.progress-circle.orange {
		&.proc-25 {
			.progress-circle-right .progress-circle-bar{
				animation: loading-3 0.25s linear forwards;		
			}
			.progress-circle-left .progress-circle-bar{
			    animation: loading-0 1s linear forwards 0.33s;
			}
		}
		&.proc-50 {
			.progress-circle-right .progress-circle-bar{
				animation: loading-1 0.5s linear forwards;		
			}
			.progress-circle-left .progress-circle-bar{
			    animation: loading-0 0.16s linear forwards 0.5s;
			}
		}
		&.proc-75 {
			.progress-circle-right .progress-circle-bar{
				animation: loading-1 0.5s linear forwards;		
			}
			.progress-circle-left .progress-circle-bar{
			    animation: loading-3 0.25s linear forwards 0.5s;
			}
		}
		&.proc-100{
			.progress-circle-right .progress-circle-bar{
				animation: loading-1 0.5s linear forwards;		
			}
			.progress-circle-left .progress-circle-bar{
			    animation: loading-1 0.5s linear forwards 0.5s;
			}
		}
	}
	@keyframes loading-1{
	    0%{
	        -webkit-transform: rotate(0deg);
	        transform: rotate(0deg);
	    }
	    100%{
	        -webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
	    }
	}
	@keyframes loading-2{
	    0%{
	        -webkit-transform: rotate(0deg);
	        transform: rotate(0deg);
	    }
	    100%{
	        -webkit-transform: rotate(144deg);
	        transform: rotate(144deg);
	    }
	}
	@keyframes loading-3{
	    0%{
	        -webkit-transform: rotate(0deg);
	        transform: rotate(0deg);
	    }
	    100%{
	        -webkit-transform: rotate(90deg);
	        transform: rotate(90deg);
	    }
	}
	@keyframes loading-4{
	    0%{
	        -webkit-transform: rotate(0deg);
	        transform: rotate(0deg);
	    }
	    100%{
	        -webkit-transform: rotate(36deg);
	        transform: rotate(36deg);
	    }
	}
	@keyframes loading-5{
	    0%{
	        -webkit-transform: rotate(0deg);
	        transform: rotate(0deg);
	    }
	    100%{
	        -webkit-transform: rotate(126deg);
	        transform: rotate(126deg);
	    }
	}
	@media only screen and (max-width: 990px){
    .progress-circle { margin-bottom: 20px; }
	}
}
