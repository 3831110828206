.profile {
	
	header {
		
		&.nav-profile {
			
			/*
			.head-close {
				position: relative;
				display: block;
				height: 68px;
				line-height: 68px;
				padding-left: 20px;
				
				i {
					position: absolute;
					top: 0px;
					right: 10px;
					font-size: 24px;
					color: $primary-color;
					cursor: pointer;
				}
			}
			*/
			
			.nav-scroll {
				margin-top:-16px;
				height: auto;
				border-bottom: 1px $primary-light solid;
				
				&.nav-scroll-mobile-only {
					display: none;
				}
				
				.box-horizontal-scroll-box {
					padding: 0px 12px 12px 12px;
				}
				
				a {
					height: 44px;
					line-height: 44px;
					@include fs-14;
					color: $gray-dark;
					border-bottom: 2px #fafafa solid;
					text-align: center;
					
					&:hover {
						color: $primary-color;
					}
					
					&.active {
						@include fw-600;
						color: $primary-color;
						border-bottom: 2px $primary-color solid;
					}
				}
			}
		}
	}

}

@media (max-width: 991px) {
	
	.profile {
		header {
			
			&.nav-profile {
				
				.nav-scroll {
					
					&.nav-scroll-mobile-only {
						display:  block;
					}
					
				}
			}
		}
		
		&-padding-right {
			padding-top:30px;
		}
	}
	
}
