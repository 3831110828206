/* all fonts mixin stuff */

@mixin font-main {
	font-family: 'Poppins', sans-serif;
}

@mixin font-head {
	font-family: 'Rubik', sans-serif;
}

@mixin font-icon {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  display: inline-block;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

@mixin fs-9 {
	font-size: 9px;
}

@mixin fs-10 {
	font-size: 10px;
}

@mixin fs-11 {
	font-size: 11px;
}

@mixin fs-12 {
	font-size: 12px;
}

@mixin fs-13 {
	font-size: 13px;
}

@mixin fs-14 {
	font-size: 14px;
}

@mixin fs-16 {
	font-size: 16px;
}

@mixin fs-18 {
	font-size: 18px;
}

@mixin fs-20 {
	font-size: 20px;
}

@mixin fs-22 {
	font-size: 22px;
}

@mixin fs-24 {
	font-size: 24px;
}

@mixin fs-26 {
	font-size: 26px;
}

@mixin fs-28 {
	font-size: 28px;
}

@mixin fs-30 {
	font-size: 30px;
}

@mixin fs-40 {
	font-size: 40px;
}

@mixin fs-50 {
	font-size: 50px;
}

@mixin fs-70 {
	font-size: 70px;
}

@mixin fw-300 {
  font-weight: 300;
}

@mixin fw-400 {
  font-weight: 400;
}

@mixin fw-500 {
  font-weight: 500;
}

@mixin fw-600 {
  font-weight: 600;
}

@mixin fw-700 {
  font-weight: 700;
}
