// used in artists adnd lexicon

@import 'vars-colors.scss';
@import 'mix-fonts.scss';

.letters-list {

	display: flex;
	justify-content: space-between;
	padding-bottom: 20px;
	border-bottom: 1px $primary-light solid;
	
	@media (max-width: 767px) {
		display: block;
	}

	a {
		width: 28px;
		height: 28px;
		line-height: 28px;
		@include fs-16;
		display: block;
		color: $secondary-dark;
		text-align: center;
		
		&:hover {
			color: $secondary-color;
		}
		
		&.active {
			background-color: $gray-superlight;
			color: $secondary-color;
		}
		
		@media (max-width: 767px) {
			display: inline-block;
		}
		
	}
	
}

